export default class GAExtension {

    /**
     * @param {Naja} naja
     */
    constructor(naja) {
        naja.addEventListener('success', this.success);
    }

    success = ({response}) => {
        if (window.ga && response.ga) {
            window.ga('send', 'pageview', response.ga);
        }
    }

}
