import Naja from "naja";
import Bootstrap from "bootstrap.native";

const
	modal = 'modal',
	show = 'show',
	before = 'before',
	after = 'after',
	hide = 'hide',
	snippet = 'snippet',
	namespace = 'nette',
	snippetBefore = `--${snippet}-${before}`,
	snippetAfter = `--${snippet}-${after}`,
	modalShow = `.${modal}.${show}`;


/**
 * Základní rozšíření ajaxové knihovny Naja
 * 1. Zobrazuje modální okna bootstrapu po načtení stránky nebo překreslení stránky
 * 2. Přidávání ke snipetům třídy before-update před překreslením nebo after-update po překreslení. Využitelné například pro přidání animací.
 * 3. Posílá události before-update.nette před překreslením nebo after-update.nette po překreslení.
 */
export default class AjaxExtension {

	/**
	 *
	 * @type {Naja}
	 */
	naja = null;

	init() {
		this.loadModals(document.body);
		[this.lang] = document.documentElement.lang.split('_');
	}

	constructor(naja) {
		this.naja = naja;
		naja.addEventListener('init', this.init.bind(this));
		naja.snippetHandler.addEventListener('beforeUpdate', this.snippetBefore.bind(this));
		naja.snippetHandler.addEventListener('afterUpdate', this.snippetAfter.bind(this));
		naja.addEventListener('error', this.error.bind(this));
		//naja.addEventListener('load', this.loadModals.bind(this));
		naja.addEventListener('interaction', this.interaction.bind(this));
		naja.addEventListener('before', this.before.bind(this));
		naja._makeRequest = naja.makeRequest;
		naja.makeRequest = function(method, url, data, options){
			method = options.method || method;
			data = options.data || data;
			url = options.url || url;
			naja._makeRequest(method, url, data, options);
		}
	}


	interaction(event) {
		const {element, options} = event;
		if (element.dataset.post) {
			options.data = JSON.parse(element.dataset.post);
			options.url = element.dataset.url || element.href;
			options.method = 'POST';
		}
	}

	before(event) {
		const {options} = event;
		if (options.post && !event.defaultPrevented) {
			event.xhr._send = event.xhr.send;
			event.xhr.send =function(){
				event.xhr.abort();
				event.xhr._send();
			};
			const settings = options.post;
			delete options.post;
			this.naja.makeRequest('POST', settings.url, settings.data, options);
			return false;
		}
	}

	loadModals(snippet) {
		for (let e of snippet.querySelectorAll(modalShow)) {
			e.classList.remove('show');
			(e.Modal || (e.Modal = new Bootstrap.Modal(e))).show();
		}
		for (let element of snippet.querySelectorAll('[data-modal]')) {
			if (!element.modalInited) {
				element.modalInited = true;
				element.addEventListener('click', (event)=> {
					this.showContentModal(element.dataset.modal, element.title);
					event.preventDefault();
					return false;
				});
			}
		}
	}

	/**
	 *
	 * @param error
	 * @param {XMLHttpRequest} xhr
	 * @param {Object} response
	 * @param options
	 */
	error({xhr, response, options}) {
		if (xhr.status === 500 || (typeof response === 'string' && response.startsWith('<!DOCTYPE html>'))) {
			if (response) {
				this.showContentModal(response);
			} else {
				var newXhr = new XMLHttpRequest();
				newXhr.onreadystatechange = ()=> {
					this.showContentModal(newXhr.responseText);
				};
				newXhr.open("GET", '/500.' + this.lang + '.html', true);
				newXhr.send();
			}
		} else {
			this.naja.fireEvent('success', {xhr, response, options});
		}
	}

	showContentModal(content, title = null) {
		const modal = document.getElementById('content-modal');
		const modalBody = modal.querySelector('.modal-body');
		const modalTitle = modal.querySelector('.modal-title');
		modalBody.innerHTML = content;
		if (modalTitle) {
			if (title !== null) {
				modalTitle.innerHTML = title;
			} else {
				const _title = modalBody.querySelector('title');
				if (_title) {
					modalTitle.innerHTML = _title.innerText;
				}
			}
		}
		(modal.Modal || new Bootstrap.Modal(modal)).show();
	}

	/**
	 *
	 * @param {Element} snippet
	 */
	snippetBefore({snippet}) {
		try {
			for (let e of snippet.querySelectorAll(modalShow)) {
				(e.Modal || (e.Modal = new Bootstrap.Modal(e))).hide();
			}
			const list = snippet.classList;
			list.add(snippetBefore);
			list.remove(snippetAfter);
			snippet.dispatchEvent(new CustomEvent(`${snippetBefore}.${namespace}`));
		} catch (e) {
			console.log(e);
		}


	}

	/**
	 *
	 * @param {Element} snippet
	 */
	snippetAfter({snippet}) {
		try {
			this.loadModals(snippet);
			const list = snippet.classList;
			list.add(snippetAfter);
			list.remove(snippetBefore);
			snippet.dispatchEvent(new CustomEvent(`${snippetAfter}.${namespace}`));
		} catch (e) {
			console.log(e);
		}
	}
}

Naja.registerExtension(AjaxExtension);
