export default class BaseExtension {
	static callbacks = ['init', 'load', 'interaction', 'before', 'start', 'abort', 'success', 'error', 'complete'];

	/** @type {Naja} */
	naja = null;

	constructor(naja) {
		this.naja = naja;
		this.constructor.callbacks.forEach((name) => {
			if (typeof this[name] === 'function') {
				naja.addEventListener(name, this[name].bind(this));
			}
		});
	}
}
