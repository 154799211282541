import Naja from 'naja';
import BaseExtension from 'utils/BaseExtension';
import GAExtension from './GAExtension';
import BSN from 'bootstrap.native';
import scrollIt from 'utils/scrollIt';



const doc = document;

class ApplicationExtension extends BaseExtension {
    /**  @type {ScrollSpy|null} */
    scrollSpy = null;
    /**  @type {string|null} */
    lang = null;
    /** @type {HTMLElement|null} */
    navigation = null;

    ellipsisTimeout;

    init() {
        this.initBootstrap(document.body);
        this.naja.snippetHandler.addEventListener('afterUpdate', this.afterSnippet.bind(this));
        this.navigation = doc.querySelector('[data-navigation]');
        [this.lang] = doc.documentElement.lang.split('_');
        window.addEventListener('resize', this.ellipsis, false);
    }

    success({response}, el) {
        if (response.scrollTop) {
            if (window.location.hash && (el = document.querySelector(window.location.hash))) {
                let rect = el.getBoundingClientRect();
                window.scrollTo(0, Math.max(rect.top + window.pageYOffset - this.navigation.offsetHeight, 0));
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    ellipsis = () => {
        clearTimeout(this.ellipsisTimeout);
        this.ellipsisTimeout = setTimeout(() => {
            for (let p of document.querySelectorAll('.ellipsis')) {
                if (typeof p.dataset.ellipsedText === 'undefined') {
                    p.dataset.ellipsedText = p.innerText;
                }
                p.innerText = p.dataset.ellipsedText;
                let clientHeight = p.clientHeight;
                while (p.scrollHeight > clientHeight) {
                    p.innerText = p.innerText.replace(/\W*\s(\S)*$/, '…');
                }
            }
        }, 20);
    }

    afterSnippet({snippet}) {
        try {
            this.initBootstrap(snippet);
        } catch (e) {
            console.log(e);
        }
    }

    initBootstrap(snippet) {
        BSN.initCallback(snippet);
        /*for (let [name, construct, selector] of BSN.supports) {
            for (let el of snippet.querySelectorAll(selector)) {
                if (!el[name]) {
                    el[name] = construct.call(construct, el);
                }
            }
        }*/
    }

    load() {
        this.ellipsis();
        let claims = doc.querySelectorAll('[data-concert-claim]');
        if (claims.length) claimVisibility(claims[0], claims[1]);

        for (let e of doc.querySelectorAll('[data-open]')) e.addEventListener('click', this.open.bind(this, e));
        for (let a of doc.querySelectorAll('a[data-scroll]')) {
            a.removeAttribute('data-scroll');
            let hash = a.getAttribute('href'), target;
            if (hash && (target = doc.querySelector(hash))) {
                a.addEventListener('click', (e) => {
                    if (window.location.hash === hash) {
                        e.preventDefault();
                    }
                    scrollIt(Math.max(target.offsetTop - this.navigation.offsetHeight, 0));
                    let collapse = a.closest('.collapse');
                    if (collapse && collapse.classList.contains('show')) {
                        let trigger = document.querySelector('[data-toggle="collapse"][data-target="#' + collapse.id + '"]');
                        if (trigger.Collapse) {
                            trigger.Collapse.hide();
                        }
                    }
                });
            }
        }
    }


    open(element, event) {
        const width = Math.min(640, screen.availWidth);
        const height = Math.min(400, screen.availHeight);
        const left = screen.availLeft + (screen.availWidth - width) / 2;
        const top = screen.availTop + (screen.availHeight - height) / 2;
        event.preventDefault();
        window.open(element.href, element.title, `width=${width},height=${height},left=${left},top=${top}`);
        return false;
    }
}

/**
 * Pro koncert promo jenom
 * @param el
 * @param el2
 */
let claimVisibility = function (el, el2) {
    setTimeout(() => {
        el.classList.add('fade-out');
        setTimeout(() => {
            el.classList.remove('fade-out');
            el.classList.add('d-none');
            el2.classList.remove('d-none');
            setTimeout(() => {
                el2.classList.remove('fade-out');
                el.classList.add('fade-out');
                claimVisibility(el2, el);
            }, 50);
        }, 500)
    }, 4000);
};

// -- Nette Ajax---------------------------------------------------------------------------------------
//InViewPort.install();
Naja.registerExtension(ApplicationExtension);
Naja.registerExtension(GAExtension);
Naja.initialize();


